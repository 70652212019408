<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" /> 短信用量列表
        </div>
      </div>
      <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :actions-type="actionsType"
        :columns="columns"
        :current-page="pageNumber"
        :hide-row-selection="false"
        :hide-pagination="false"
        @emitSelectChange="selectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        ref="rt"
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :current-page="pageNumber"
        :actions-type="actionsType"
        :is-full="false"
        :hide-row-selection="false"
        :hide-pagination="false"
        @emitRefreshData="getData"
        @emitSelectChange="selectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row :gutter="8" class="btn--container">
          <a-col class="gutter-row" :span="6">
            <a-input
              v-model="searchDto.filterText"
              :placeholder="l('请输入姓名...')"
            />
          </a-col>
          <a-col class="gutter-row" :span="3">
            <a-select
              v-model="searchDto.sendType"
              style="width: 100%"
              show-search
              placeholder="类型筛选"
            >
              <a-select-option
                v-for="item in SendTypes"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col class="gutter-row" :span="7">
            <a-button type="primary" @click="searchData">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
        </a-row>
      </ele-table>

      <!-- <div class="modal-footer">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addTemplete">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div> -->
    </section>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";

export default {
  name: "signperson-list",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      sendType: undefined,
      filterText: "",
      spinning: false,
      // 表单
      formLayout: "horizontal",
      isDisabled: false,

      tableKey: "",
      commonService: null,
      personnelService: null,

      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已经签署", "归档", "已通知"],
      SendTypes: [
        { label: "登录", value: 1 },
        { label: "注册", value: 2 },
      ],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      // 用户表格
      columns: [
        {
          title: "姓名",
          dataIndex: "realName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: "手机号码",
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: "身份证",
          dataIndex: "idCard",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: "类型",
          dataIndex: "sendType",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "sendType" },
          customRender: (text) => {
            if (text == 1) {
              return <a-tag color="#87d068">登录</a-tag>;
            }
            return <a-tag color="#87d068">注册</a-tag>;
          },
        },
        {
          title: "发送时间",
          dataIndex: "sendTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "sendTimeStr" },
        },
        {
          title: "短信验证码",
          dataIndex: "codeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "codeStr" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      searchDto: {
        sendType: undefined,
        agreementTemplateId: "",
        projectId: null,
        signStatus: "",
        filterText: "",
        sorting: "",
        MaxResultCount: 50,
        SkipCount: 0,
      },
      projectData: [
        {
          value: null,
          text: "请选择",
        },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.getData();
  },
  methods: {
    getData() {
      this._getPersonPagedByRealNameList();
    },
    searchData() {
      this.searchDto.SkipCount = 0;
      this._getPersonPagedByRealNameList();
    },
    // async getProject() {
    //   this.spinning = true;
    //   try {
    //     let res = await this.commonService.post({
    //       url: "/api/services/app/ProjectUser/GetRealUserList",
    //       params: {
    //         projectId: this.projectId
    //       },
    //     });
    //     this.projectData = res.map((item) => {
    //       return {
    //         value: item.id,
    //         text: item.projectName,
    //       };
    //     });
    //     // this.selectProjectList = res.result;
    //     this.spinning = false;
    //   } catch (error) {
    //     this.spinning = false;
    //   }
    // },
    async _getPersonPagedByRealNameList() {
      this.spinning = true;
      this.$refs.rt.clearData();
      try {
        const params = {
          projectId: this.projectId,
          yearMonth: this.yearMonth,
          type: this.type,
          filterText: this.searchDto.filterText,
          sendType: this.searchDto.sendType,
          sorting: this.searchDto.sorting,
          maxResultCount: this.searchDto.MaxResultCount,
          skipCount: this.searchDto.SkipCount,
        };
        // console.log(params);
        let res = await this.commonService.post({
          url: "/api/services/app/ProjectUser/GetSmsUsageList",
          params: params,
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            sendType: item.sendType == 1 ? "登录" : "注册",
            sendTypeColor: item.sendType == 1 ? "#87d068" : "#87d068",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.searchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.searchDto.MaxResultCount + 1,
          this.pageNumber * this.searchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _getProjectPersonnelAgreement() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectPersonnelAgreement/GetList",
          params: {
            personnelId: this.personnelAgreementItem.userId,
            projectId: this.personnelAgreementItem.projectId,
            contractNo: this.filterText,
          },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTimeStr: item.signTime
              ? moment(item.signTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            creationTimeStr: item.creationTime
              ? moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            signStatusStr: this.signStatuList[item.signStatus],
          };
        });
        this.totalItems;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _agreementTemplateGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectPersonnelAgreement/GetSelectData",
          params: {
            projectId: this.personnelAgreementItem.projectId,
          },
        });
        this.spinning = false;
        this.seclectModalParams.selectList = res;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [],
        },
      };
      if (this.signstatus == 1) {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("预览协议"),
            icon: "eye",
            fn: (data) => {
              console.log(data);
              if (data.viewUrl) {
                window.open(data.viewUrl);
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
          {
            granted: true,
            name: this.l("下载协议"),
            icon: "download",
            fn: (data) => {
              if (data.downloadUrl) {
                window.location.href = data.downloadUrl;
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
        ];
      } else {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("通知签署"),
            icon: "notification",
            fn: (data) => {
              console.log(data);
              if (data.isNoty) {
                _this.$message.info("已通知过!");
                return;
              }
              let personnelIds = [];
              personnelIds.push(data.personId);
              _this.notificationPersonnelAgreement(data.id, personnelIds);
            },
          },
          {
            granted: true,
            name: this.l("撤回"),
            icon: "delete",
            fn: (data) => {
              console.log(data);
              let personnelIds = [];
              personnelIds.push(data.personId);
              _this.delPersonnelAgreement(data.id, personnelIds);
            },
          },
        ];
      }
      this.actionsType = obj;
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.searchDto.projectId = "";
      this.searchDto.filterText = "";
      this.searchDto.sendType = undefined;
      this.getData();
    },
    delPersonnelAgreement(projectAgreementId, personnelIds) {
      let _that = this;
      this.message.confirm("是否确定撤回", (res) => {
        if (res) {
          _that.delData(projectAgreementId, personnelIds);
        }
      });
    },
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    batchDelData() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "撤回"));
        return;
      }
      console.log(this.selectedRowKeys);
      console.log(this.selectedRows);
      let personnelIds = this.selectedRows.map((item) => {
        return item.personId;
      });
      let projectAgreementId = this.selectedRows.map((item) => {
        return item.id;
      });
      let projectAgreementIdArr = projectAgreementId.filter(function (
        item,
        index,
        projectAgreementId
      ) {
        return projectAgreementId.indexOf(item, 0) === index;
      });
      let _that = this;
      _that.message.confirm(
        `是否对${personnelIds.length}个人进行撤回`,
        "撤回",
        (res) => {
          if (res) {
            _that.delData(projectAgreementIdArr[0], personnelIds);
          }
        }
      );
    },
    async delData(projectAgreementId, personnelIds) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/Revocation",
          params: {
            projectAgreementId: projectAgreementId,
            personnelIds: personnelIds,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: res,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    batchDownload() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "下载"));
        return;
      }
    },
    notification() {
      console.log(this.selectedRowKeys);
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通知签署"));
        return;
      }
      console.log(this.selectedRowKeys);
      console.log(this.selectedRows);
      let personnelIds = this.selectedRows.map((item) => {
        return item.personId;
      });
      let projectAgreementId = this.selectedRows.map((item) => {
        return item.id;
      });
      let projectAgreementIdArr = projectAgreementId.filter(function (
        item,
        index,
        projectAgreementId
      ) {
        return projectAgreementId.indexOf(item, 0) === index;
      });
      let _that = this;
      _that.message.confirm(
        `是否对${personnelIds.length}个人进行通知签署`,
        "系统提示",
        (res) => {
          if (res) {
            _that.notificationData(projectAgreementIdArr[0], personnelIds);
          }
        }
      );
    },
    notificationPersonnelAgreement(projectAgreementId, personnelIds) {
      let _that = this;
      this.message.confirm("是否确定通知签署", (res) => {
        if (res) {
          _that.notificationData(projectAgreementId, personnelIds);
        }
      });
    },
    async notificationData(projectAgreementId, personnelIds) {
      this.spinning = true;

      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/SendSignNotice",
          params: {
            projectAgreementId: projectAgreementId,
            personnelIds: personnelIds,
          },
        });
        this.refreshGoFirstPage();
        console.log(res);
        this.$notification["success"]({
          message: res,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange(data) {
      let { selectedRowKeys, selectedRows } = data;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleChange(value) {
      this.searchDto.projectId = value;
      // console.log(value);
    },
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.searchDto.SkipCount = (page - 1) * this.searchDto.MaxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
  },
};
</script>

<style scoped lang="less">
// @import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
